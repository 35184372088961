import { LoadLayoutData } from '@saagie-internal/layout-lib';

export const showLayout = (appName, platformId) => {
  const layout = document.querySelector('saagie-layout');
  layout.removeAttribute('hide');
  new LoadLayoutData().init(appName, platformId);
};

export const hideLayout = () => {
  const layout = document.querySelector('saagie-layout');
  layout.setAttribute('hide', 'true');
};
