export const activeFocusMode = () => {
  const layout = document.querySelector('saagie-layout');
  if (layout) {
    layout.setAttribute('focus-mode', true);
  }
};

export const desactiveFocusMode = () => {
  const layout = document.querySelector('saagie-layout');
  if (layout) {
    layout.removeAttribute('focus-mode');
  }
};
