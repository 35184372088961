import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Button } from 'saagie-ui/react';
import PageLayout from '../components/layout/PageLayout';
import { hideLayout, showLayout } from '../utils/layout';
import { PLATFORMS_URL } from '../constants/url';
import { APP_NAME } from '../constants/app-name';

export const HttpErrorPage = ({ message, code }) => {
  useEffect(() => {
    showLayout(APP_NAME);

    return () => { hideLayout(); };
  }, []);

  return (
    <PageLayout>
      <Container gutter="xl@sm" size="sm" className="sui-h-text-center">
        <h1>
          {message}
          <small
            style={{
              display: 'block',
              fontSize: '.8em',
              opacity: 0.5,
            }}
          >
            {code}
          </small>
        </h1>
        <div className="sui-h-mv-xxl">
          <Button color="primary" tag={Link} to={PLATFORMS_URL}>
            Back to all platforms
          </Button>
        </div>
        <div className="sui-h-mv-xxl">
          <img width={500} src="/404.svg" alt="" />
        </div>
      </Container>
    </PageLayout>
  );
};

HttpErrorPage.propTypes = {
  message: PropTypes.string,
  code: PropTypes.string,
};

HttpErrorPage.defaultProps = {
  message: 'Page Not Found',
  code: '404',
};
