import React, { useState } from 'react';
import Formsy from 'formsy-react';
import { useNotification, Button, Container } from 'saagie-ui/react';
import { FieldInput } from 'saagie-ui/react/formsy';
import PageLayout from '../components/layout/PageLayout';
import { forgotPassword } from '../services/userService';
import { GENERIC_CODE, LOGIN_CODE } from '../constants/error-codes';
import { SEPARATOR_LINE_FORM_VALIDATION, UNKNOWN_ERROR } from '../constants/error-validation';

export const ForgotPassword = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { throwSuccess, throwError, clearNotification } = useNotification();

  const onSubmit = async ({ username }, _, updateInputsWithError) => {
    try {
      setIsLoading(true);
      await forgotPassword(username);

      const notification = throwSuccess(
        'An email has been sent with further instructions.',
        <Button
          onClick={() => {
            window.history.back();
            clearNotification(notification);
          }}
          color="success"
        >
          Back
        </Button>,
        { duration: 6 },
      );
    } catch (error) {
      const { errors } = error.response.data;

      if (errors) {
        const loginError = errors.find((er) => er.errorCode === LOGIN_CODE);
        const genericError = errors.find((er) => er.errorCode === GENERIC_CODE);

        const updateInput = (err) => {
          updateInputsWithError(
            {
              username: err.messages
                .map((message) => message.defaultMessage)
                .reduce((accumulator, currentValue) => accumulator
                  .concat(SEPARATOR_LINE_FORM_VALIDATION)
                  .concat(currentValue)),
            },
            true,
          );
        };

        if (genericError) {
          updateInput(genericError);
        }

        if (loginError) {
          updateInput(loginError);
        }

        setIsFormValid(false);
      } else {
        throwError(
          UNKNOWN_ERROR,
          <Button onClick={window.location.reload()} />,
        );
      }
    }

    setIsLoading(false);
  };

  return (
    <PageLayout isWithTopBar>
      <Container gutter="xl@sm" size="xs">
        <h2 className="sui-h-text-center">
          Forgot your password?
        </h2>
        <p className="sui-h-text-center sui-h-mb-md">
          Enter your username to reset your password.
        </p>
        <Formsy
          onValidSubmit={onSubmit}
          onInvalid={() => setIsFormValid(false)}
          onValid={() => setIsFormValid(true)}
        >
          <FieldInput
            name="username"
            label="Username"
            required
            validationErrors={{
              isDefaultRequiredValue: 'Please enter your username.',
            }}
          />
          <div className="sui-g-grid as--auto">
            <div className="sui-g-grid__item as--pull">
              <Button onClick={() => window.history.back()}>
                Back
              </Button>
            </div>
            <div className="sui-g-grid__item">
              <Button
                color="primary"
                isDisabled={!isFormValid}
                isLoading={isLoading}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </div>
        </Formsy>
      </Container>
    </PageLayout>
  );
};
