import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'saagie-ui/react';
import illustrationUrl from '../../assets/images/illustration-cube-platform.svg';

const cardColor = [
  '#f99f2f',
  '#f78559',
  '#f16666',
  '#B789E7',
  '#979CDF',
  '#7FABCB',
];

const PlatformCard = ({
  colorIndex,
  isGhost,
  name,
  url,
}) => (
  <>
    { isGhost
      ? (
        <div className="sui-m-card slg-m-card as--skeleton" />
      ) : (
        <div className="sui-m-card slg-m-card" style={{ backgroundImage: `url(${illustrationUrl})`, backgroundColor: cardColor[colorIndex % 6] }}>
          <a className="slg-m-card__content" href={url}>
            <div className="slg-m-card__title">
              <small>
                Platform
              </small>
              {name}
            </div>
            <div className="slg-m-card__action">
              <Button
                isBlock
                size="lg"
                type="button"
                className="slg-a-button--outline-white"
                style={{
                  '--dataColor': cardColor[colorIndex % 6],
                }}
                aria-label="Platform access"
                data-label="Platform access"
              />
            </div>
          </a>
        </div>
      )}
  </>
);

PlatformCard.propTypes = {
  colorIndex: PropTypes.number,
  isGhost: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
};

PlatformCard.defaultProps = {
  colorIndex: 0,
  isGhost: false,
  name: '',
  url: '',
};

export default PlatformCard;
