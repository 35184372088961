import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Button, useNotification } from 'saagie-ui/react';
import { FieldPassword } from 'saagie-ui/react/formsy';
import PageLayout from '../components/layout/PageLayout';
import { hideLayout, showLayout } from '../utils/layout';
import { login as loginUser, changePassword, getCurrentUser } from '../services/userService';
import { UNKNOWN_ERROR } from '../constants/login-error-messages';
import PageLoader from '../components/layout/PageLoader';
import { LOGIN_URL } from '../constants/url';
import { APP_NAME } from '../constants/app-name';

const ChangePassword = () => {
  const [username, setUsername] = useState('');

  const [isInitLoading, setIsInitLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const [errors, setErrors] = useState(undefined);

  // Hack for Formsy to reset the form.
  const [resetKey, setResetKey] = useState(0);

  const history = useHistory();

  const { throwSuccess } = useNotification();

  useEffect(() => {
    showLayout(APP_NAME);

    const fetchCurrentUser = async () => {
      try {
        const response = await getCurrentUser();
        const { login } = response.data;
        setUsername(login);
        setIsInitLoading(false);
      } catch (_) {
        history.push(LOGIN_URL);
      }
    };

    fetchCurrentUser();

    return () => {
      hideLayout();
    };
  }, [history]);

  const handleSubmit = async ({ password, newPassword }) => {
    setIsFormLoading(true);
    setErrors(undefined);

    try {
      await changePassword(username, password, newPassword);
      // Re log-in user that has been logout
      await loginUser(username, newPassword);

      // Refresh cookie
      const response = await getCurrentUser();
      const { login } = response.data;
      setResetKey((s) => s + 1);
      setUsername(login);
      setIsFormLoading(false);

      throwSuccess('Password updated successfully', null, { duration: 6 });
    } catch (error) {
      setErrors([error.response.data?.errors?.[0].messages?.[0].defaultMessage ?? UNKNOWN_ERROR]);
      setIsFormLoading(false);
    }
  };

  if (isInitLoading) {
    return <PageLoader />;
  }

  return (
    <PageLayout>
      <div className="sui-l-container as--gutter-xl@sm as--size-xxs">
        <h2 className="sui-h-text-center">
          Change password
        </h2>
        <Formsy onValidSubmit={handleSubmit} key={resetKey}>
          {errors && (
            <div className="sui-m-message as--danger sui-h-text-center">
              { errors.map((error) => (
                <div key={error}>
                  {error}
                </div>
              )) }
            </div>
          )}

          <FieldPassword
            label="Old password"
            name="password"
            required
            validationErrors={{
              isDefaultRequiredValue: 'The old password is required',
            }}
          />
          <FieldPassword
            label="New password"
            name="newPassword"
            required
            validationErrors={{
              isDefaultRequiredValue: 'Please provide a new password',
            }}
          />
          <FieldPassword
            label="Confirm new password"
            name="newPasswordConfirmation"
            required
            validations={{
              isEqualToNewPassword: (values, value) => (value || '') === values.newPassword,
            }}
            validationErrors={{
              isDefaultRequiredValue: 'Please confirm the new password',
              isEqualToNewPassword: 'Password confirmation doesn\'t match the password',
            }}
          />

          <div className="sui-m-form-group">
            <div className="sui-g-grid as--auto as--middle as--end">
              <div className="sui-g-grid__item as--pull">
                <Button color="secondary" onClick={() => window.history.back()}>
                  Cancel
                </Button>
              </div>
              <div className="sui-g-grid__item">
                <Button type="submit" color="primary" isLoading={isFormLoading}>
                  Change Password
                </Button>
              </div>
            </div>
          </div>
        </Formsy>
      </div>
    </PageLayout>
  );
};

export default ChangePassword;
