import React from 'react';
import { LoadLayoutData } from '@saagie-internal/layout-lib';
import PageLayout from '../components/layout/PageLayout';
import PlatformCard from '../components/platform/PlatformCard';
import { hideLayout } from '../utils/layout';
import PageLoader from '../components/layout/PageLoader';

class PlatformList extends React.Component {
  constructor(props) {
    super(props);
    this.loadLayoutData = new LoadLayoutData();
    this.state = {
      authorizations: [],
      loading: true,
    };
    this.loadLayoutData.init()
      .then(({ rights }) => this.setState({
        authorizations: rights.authorizations
          .filter((authorization) => authorization.permissions.length !== 0),
        loading: false,
      }));
  }

  componentDidMount() {
    const layout = document.querySelector('saagie-layout');
    layout.removeAttribute('hide');
  }

  componentWillUnmount() {
    hideLayout();
  }

  render() {
    const { authorizations, loading } = this.state;
    return (
      <PageLayout>
        <div className="sui-l-container as--size-md as--gutter-horizontal-lg as--gutter-vertical-xl@sm">
          {
            !loading && (
              <div className="sui-l-page-header">
                <div className="sui-l-page-header__primary">
                  <div className="sui-l-page-header__title">
                    <div className="sui-l-page-header__title-text">
                      All platforms
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            loading && (
              <PageLoader />
            )
          }
          {
            !loading && !authorizations.length && (
              <div className="sui-m-message sui-h-text-center">
                <h3 className="sui-h-m-none">
                  You’re not allowed to any platforms
                </h3>
                <p>
                  The privileges you’ve been assigned do not allow you to access any platforms.
                  <br />
                  <strong>
                    Please contact your admin.
                  </strong>
                </p>
              </div>
            )
          }
          {
            !loading && !!authorizations.length && authorizations.length < 3 && (
              <div className="sui-g-grid as--gutter-lg as--stretch">
                { authorizations
                  .sort((auth1, auth2) => auth1.platformName.localeCompare(auth2.platformName))
                  .map((authorization, index) => (
                    <div className="sui-g-grid__item as--1_2@xs as--1_3@md as--1_3@xl" key={authorization.platformId}>
                      <PlatformCard
                        colorIndex={index}
                        name={authorization.platformName}
                        url={this.loadLayoutData.getPlatformUrl('', authorization.platformId)}
                      />
                    </div>
                  ))}
                <div className="sui-g-grid__item as--1_2@xs as--1_3@md as--1_3@xl">
                  <PlatformCard
                    isGhost
                  />
                </div>
                { authorizations.length === 1 && (
                  <div className="sui-g-grid__item as--1_2@xs as--1_3@md as--1_3@xl">
                    <PlatformCard
                      isGhost
                    />
                  </div>
                )}
              </div>
            )
          }
          {
            !loading && authorizations.length > 2
              && (
                <div className="sui-g-grid as--gutter-lg as--stretch">
                  {
                    authorizations.map((authorization, index) => (

                      <div className="sui-g-grid__item as--1_2@xs as--1_3@md" key={authorization.platformId}>
                        <PlatformCard
                          colorIndex={index}
                          name={authorization.platformName}
                          url={this.loadLayoutData.getPlatformUrl('', authorization.platformId)}
                        />
                      </div>
                    ))
                  }
                </div>
              )
          }
        </div>
      </PageLayout>
    );
  }
}

export default PlatformList;
