/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { FormPassword, FormGroup, Button } from 'saagie-ui/react';

const ChangePasswordForm = ({
  errors,
  isLoading,
  password,
  newPassword,
  newPasswordConfirmation,
  onChange,
  onSubmit,
  showBackButton,
  showCurrentPasswordField,
}) => (
  <form onSubmit={onSubmit}>
    {errors ? (
      <div className="sui-m-message as--danger sui-h-text-center">
        { errors.map((error) => (
          <div key={error}>
            {error}
          </div>
        )) }
      </div>
    ) : ''}

    {showCurrentPasswordField && (
      <FormGroup label="Old password" validationState={errors && !password ? 'danger' : null}>
        <FormPassword id="password" name="password" value={password} onChange={onChange} />
      </FormGroup>
    )}

    <FormGroup label="New password" validationState={errors && !newPassword ? 'danger' : null}>
      <FormPassword id="new-password" name="newPassword" value={newPassword} onChange={onChange} />
    </FormGroup>

    <FormGroup label="Confirm new password" validationState={(errors && !newPasswordConfirmation) || (errors && newPassword !== newPasswordConfirmation) ? 'danger' : null}>
      <FormPassword id="new-password-confirmation" name="newPasswordConfirmation" value={newPasswordConfirmation} onChange={onChange} />
    </FormGroup>

    <div className="sui-m-form-group">
      <div className="sui-g-grid as--auto as--middle as--end">
        {showBackButton ? (
          <div className="sui-g-grid__item as--pull">
            <Button color="secondary" onClick={() => window.history.back()}>
              Cancel
            </Button>
          </div>
        ) : ''}
        <div className="sui-g-grid__item">
          <Button type="submit" color="primary" isLoading={isLoading}>
            Change Password
          </Button>
        </div>
      </div>
    </div>

  </form>
);

ChangePasswordForm.propTypes = {
  errors: PropTypes.array,
  isLoading: PropTypes.bool,
  newPassword: PropTypes.string,
  newPasswordConfirmation: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  password: PropTypes.string,
  showBackButton: PropTypes.bool,
  showCurrentPasswordField: PropTypes.bool,
};

ChangePasswordForm.defaultProps = {
  errors: null,
  isLoading: false,
  newPassword: '',
  newPasswordConfirmation: '',
  password: '',
  showBackButton: false,
  showCurrentPasswordField: false,
};

export default ChangePasswordForm;
