import React from 'react';
import OfflineLayout from '../components/layout/OfflineLayout';
import { LOGIN_URL } from '../constants/url';

// Used only for Single Sign-On to prevent auto-redirect to the Single Sign-On page
const Logout = () => (
  <OfflineLayout>
    <h4 className="sui-h-text-center sui-h-mb-xxs">
      You have been logged out from
    </h4>
    <h1 className="sui-h-text-center sui-h-text-brand-primary sui-h-mt-xxs">
      Saagie
    </h1>

    <div className="slg-c-sso-list sui-h-mt-lg">
      <a
        href={LOGIN_URL}
        className="sui-a-button as--block"
      >
        Single Sign-on
      </a>
    </div>
  </OfflineLayout>
);

export default Logout;
