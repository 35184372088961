/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, FormPassword, FormGroup } from 'saagie-ui/react';
import { FORGOT_PASSWORD_URL, LOGIN_URL } from '../../constants/url';

const LoginForm = ({
  errors, username, password, isLoading, onChange, onSubmit, isReadOnly,
}) => (
  <form onSubmit={onSubmit}>
    <h4 className="sui-h-text-center sui-h-mb-xxs">
      Welcome back to
    </h4>
    <h1 className="sui-h-text-center sui-h-text-brand-primary sui-h-mt-xxs">
      Saagie
    </h1>

    {errors ? (
      <div className="sui-m-message as--danger sui-h-text-center">
        { errors.map((error) => (
          <div key={error}>
            {error}
          </div>
        )) }
      </div>
    ) : ''}

    <FormGroup label="Username" validationState={errors && !username ? 'danger' : null}>
      <input
        id="username"
        name="username"
        className="sui-a-form-control"
        value={username}
        onChange={onChange}
      />
    </FormGroup>
    <FormGroup label="Password" validationState={errors && !password ? 'danger' : null}>
      <FormPassword id="password" name="password" value={password} onChange={onChange} />
    </FormGroup>

    <div className="sui-m-form-group">
      <div className="sui-g-grid as--auto as--middle as--end">
        {!isReadOnly && (
          <div className="sui-g-grid__item as--pull">
            <Link to={FORGOT_PASSWORD_URL}>
              Forgot password?
            </Link>
          </div>
        )}
        <div className="sui-g-grid__item">
          <Button color="primary" isLoading={isLoading} type="submit">
            Login
          </Button>
        </div>
      </div>
    </div>
    {window.ENV?.authProvider === 'sso' && (
      <>
        <div className="slg-c-sso-divider sui-h-mt-lg">or</div>
        <div className="slg-c-sso-list sui-h-mt-lg">
          <a
            href={LOGIN_URL}
            className="sui-a-button as--block"
          >
            Single Sign-on
          </a>
        </div>
      </>
    )}
  </form>
);

LoginForm.propTypes = {
  errors: PropTypes.array,
  isLoading: PropTypes.bool,
  password: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  username: PropTypes.string,
  isReadOnly: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {
  errors: null,
  isLoading: true,
  password: '',
  username: '',
};

export default LoginForm;
