import 'react-app-polyfill/ie9'; // For IE 9-11 support
import React from 'react';
import { render } from 'react-dom';
import { HelpDesk } from 'common-ui';
import { App } from './App';
import './scss/index.scss';

render((
  <>
    <App />
    <HelpDesk />
  </>
), document.getElementById('root'));
