import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PageLayout = ({ children, isWithTopBar }) => (
  <div className="sui-l-app-layout__page">
    <div className="sui-l-app-layout__page-scroll">
      {
        isWithTopBar && (
          <div className="sui-o-topbar">
            <Link to="/" className="sui-o-topbar__logo" style={{ borderRight: '0' }}>
              <img src="/logo-red.svg" alt="Saagie" />
            </Link>
          </div>
        )
      }
      { children }
    </div>
  </div>
);

PageLayout.propTypes = {
  children: PropTypes.node,
  isWithTopBar: PropTypes.bool,
};

PageLayout.defaultProps = {
  children: '',
  isWithTopBar: false,
};

export default PageLayout;
