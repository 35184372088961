import React, { useState } from 'react';
import Formsy from 'formsy-react';
import { Button, useNotification, Container } from 'saagie-ui/react';
import { FieldPassword } from 'saagie-ui/react/formsy';
import { useParams } from 'react-router-dom';
import PageLayout from '../components/layout/PageLayout';
import { resetPassword } from '../services/userService';
import { GENERIC_CODE, PASSWORD_CODE } from '../constants/error-codes';
import LoginRedirect from '../components/router/LoginRedirect';
import { SEPARATOR_LINE_FORM_VALIDATION, UNKNOWN_ERROR } from '../constants/error-validation';

export const ResetPassword = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);

  const { token } = useParams();
  const { throwError } = useNotification();

  const onSubmit = async ({ password: passwordInput }, _, updateInputsWithError) => {
    try {
      setIsLoading(true);
      await resetPassword(passwordInput, token);

      setIsResetPassword(true);
    } catch (error) {
      const { errors } = error.response.data;
      if (errors) {
        const passwordError = errors.find((er) => er.errorCode === PASSWORD_CODE);
        const genericError = errors.find((er) => er.errorCode === GENERIC_CODE);

        const updateInput = (err) => {
          updateInputsWithError(
            {
              password: err.messages
                .map((message) => message.defaultMessage)
                .reduce((accumulator, currentValue) => accumulator
                  .concat(SEPARATOR_LINE_FORM_VALIDATION)
                  .concat(currentValue)),
            },
            true,
          );
        };

        if (genericError) {
          updateInput(genericError);
        }

        if (passwordError) {
          updateInput(passwordError);
        }
      } else {
        throwError(
          UNKNOWN_ERROR,
          <Button onClick={window.location.reload()} />,
        );
      }
    }
    setIsLoading(false);
  };

  if (isResetPassword) {
    return (
      <LoginRedirect />
    );
  }

  return (
    <PageLayout isWithTopBar>
      <Container gutter="xl@sm" size="xs">
        <h2 className="sui-h-text-center sui-h-mb-lg">
          Reset your password
        </h2>
        <Formsy
          onValidSubmit={onSubmit}
          onInvalid={() => setIsFormValid(false)}
          onValid={() => setIsFormValid(true)}
        >
          <FieldPassword
            name="password"
            label="New password"
            required
            value={password}
            onChange={setPassword}
            validationErrors={{
              isDefaultRequiredValue: 'Please enter your new password.',
            }}
          />
          <FieldPassword
            name="passwordConfirmation"
            label="Confirm new password"
            required
            value={passwordConfirmation}
            onChange={setPasswordConfirmation}
            validations={{
              isConfirmPassword: () => passwordConfirmation === password,
            }}
            validationErrors={{
              isDefaultRequiredValue: 'Please confirm your new password.',
              isConfirmPassword: 'Password does not match.',
            }}
          />
          <div className="sui-m-form-group">
            <div className="sui-g-grid as--auto as--end">
              <div className="sui-g-grid__item">
                <Button
                  color="primary"
                  isDisabled={!isFormValid}
                  isLoading={isLoading}
                  type="submit"
                >
                  Reset password
                </Button>
              </div>
            </div>
          </div>
        </Formsy>
      </Container>
    </PageLayout>
  );
};
