import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import OfflineLayout from '../components/layout/OfflineLayout';
import { changePassword, getProfile, login } from '../services/userService';
import LoginForm from '../components/login/LoginForm';
import ChangePasswordForm from '../components/login/ChangePasswordForm';
import {
  EMPTY_LOGIN_PASSWORD,
  EMPTY_NEW_PASSWORD,
  NEW_PASSWORDS_NO_MATCH,
  UNKNOWN_ERROR,
} from '../constants/login-error-messages';
import { ProfileFormLogin } from '../components/login/ProfileFormLogin';

const Login = ({ onLogin, isReadOnly }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [isPasswordExpired, setIsPasswordExpired] = useState(false);
  const [displayProfileForm, setDisplayProfileForm] = useState(false);

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const apiError = searchParams.get('error') ?? null;
  const loginErrors = apiError ? [apiError] : formErrors;

  const handleInput = (event) => {
    const { name, value } = event.target;
    if (name === 'username') setUsername(value);
    if (name === 'password') setPassword(value);
    if (name === 'newPassword') setNewPassword(value);
    if (name === 'newPasswordConfirmation') setNewPasswordConfirmation(value);
  };

  const handleSubmitLogin = (event) => {
    event.preventDefault();

    setIsLoading(true);
    setFormErrors(null);

    if (!username || !password) {
      setIsLoading(false);
      setFormErrors([EMPTY_LOGIN_PASSWORD]);
      return;
    }

    login(username, password)
      .then(() => {
        setIsLoading(false);
        setFormErrors(null);
        setIsPasswordExpired(false);
        setDisplayProfileForm(isReadOnly);
        onLogin();
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          const errors = error.response.data;
          const passwordExpired = errors?.errors?.[0]?.errorCode === 'saagie.generic' && errors?.errors?.[0]?.messages?.[0]?.errorCode === 'expired';
          if (passwordExpired) {
            setIsLoading(false);
            setFormErrors(null);
            setIsPasswordExpired(passwordExpired);
            setDisplayProfileForm(isReadOnly);
          } else {
            setIsLoading(false);
            setFormErrors(
              (error.response.status !== 401) ? [UNKNOWN_ERROR] : errors
                ?.errors?.flatMap((e) => e.messages?.map((m) => m.defaultMessage)) ?? UNKNOWN_ERROR,
            );
          }
        } else {
          setIsLoading(false);
          setFormErrors([UNKNOWN_ERROR]);
        }
      });
  };

  const handleSubmitExpiredPassword = (event) => {
    event.preventDefault();

    setIsLoading(true);
    setFormErrors(null);

    if (!newPassword || !newPasswordConfirmation) {
      setIsLoading(false);
      setFormErrors([EMPTY_NEW_PASSWORD]);
      return;
    }

    if (newPassword !== newPasswordConfirmation) {
      setIsLoading(false);
      setFormErrors([NEW_PASSWORDS_NO_MATCH]);
      return;
    }

    changePassword(username, password, newPassword)
      .then(() => login(username, newPassword))
      .then(() => getProfile(username))
      .then((response) => {
        const { job, email } = response.data;
        const isCompletedProfile = !!job && !!email;
        if (isCompletedProfile) {
          onLogin();
        } else {
          setFormErrors(null);
          setIsPasswordExpired(false);
          setDisplayProfileForm(true);
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          const errors = error.response.data;
          setIsLoading(false);
          setFormErrors(
            errors?.errors
              ?.flatMap((e) => e.messages?.map((m) => m.defaultMessage)) ?? UNKNOWN_ERROR,
          );
        } else {
          setIsLoading(false);
          setFormErrors([UNKNOWN_ERROR]);
        }
      });
  };

  if (isPasswordExpired && !isReadOnly) {
    return (
      <OfflineLayout>
        <h2 className="sui-h-text-center sui-h-mb-md">
          Change your password
        </h2>
        <p className="sui-h-text-center sui-h-mb-md">
          To continue, you need to change your password for security reasons.
        </p>
        <ChangePasswordForm
          errors={formErrors}
          username={username}
          password={password}
          newPassword={newPassword}
          newPasswordConfirmation={newPasswordConfirmation}
          isLoading={isLoading}
          onChange={handleInput}
          onSubmit={handleSubmitExpiredPassword}
        />
      </OfflineLayout>
    );
  }

  if (displayProfileForm) {
    return (
      <OfflineLayout>
        <h2 className="sui-h-text-center sui-h-mb-md">Profile</h2>
        <ProfileFormLogin
          login={username}
          onLogin={onLogin}
          isReadOnly={isReadOnly}
        />
      </OfflineLayout>
    );
  }

  return (
    <OfflineLayout>
      <LoginForm
        errors={loginErrors}
        username={username}
        password={password}
        isLoading={isLoading}
        onChange={handleInput}
        onSubmit={handleSubmitLogin}
        isReadOnly={isReadOnly}
      />
    </OfflineLayout>
  );
};

Login.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
};

export default Login;
