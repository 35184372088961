export function getPlatformId() {
  const pathnameArray = window.location.pathname.split('/');

  if (pathnameArray && pathnameArray.length > 3) {
    return parseInt(pathnameArray[3], 10);
  }

  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    return 13;
  }

  return null;
}
