import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import PageLoader from '../layout/PageLoader';
import { PLATFORMS_URL, LOGIN_URL } from '../../constants/url';

export const HomeRedirect = ({ location: { search } }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const redirectLink = queryString.parse(search).redirect;

  if (isLoading) {
    return <PageLoader />;
  }

  if (redirectLink && redirectLink.startsWith('/') && !redirectLink.startsWith('//') && redirectLink !== '/' && redirectLink !== LOGIN_URL) {
    window.location.replace(redirectLink);
    return null;
  }

  return <Redirect to={PLATFORMS_URL} />;
};

HomeRedirect.propTypes = {
  location: PropTypes.object.isRequired,
};
