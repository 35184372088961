import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SINGLE_SIGN_ON_CHALLENGE_URL } from '../constants/url';

const SingleSignOn = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const path = searchParams.get('redirect') ?? '/';

  window.location.replace(`${SINGLE_SIGN_ON_CHALLENGE_URL}?redirect=${path}`);

  return <>Will redirect on your single sign-on provider...</>;
};

export default SingleSignOn;
