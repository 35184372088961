import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoginRedirect from './LoginRedirect';
import PageLoader from '../layout/PageLoader';

/**
 * Routes to a component or another depending on user's login state
 */
const AuthRoute = ({
  isLoading,
  isAuthenticated,
  authComponent: AuthComponent,
  unAuthComponent: UnAuthComponent,
  loadingComponent: LoadingComponent,
  ...rest
}) => {
  if (isLoading) {
    return (<LoadingComponent />);
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated ? <AuthComponent {...props} /> : <UnAuthComponent {...props} />
      )}
    />
  );
};

AuthRoute.propTypes = {
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  authComponent: PropTypes.func.isRequired,
  unAuthComponent: PropTypes.func,
  loadingComponent: PropTypes.func,
};

AuthRoute.defaultProps = {
  isLoading: true,
  isAuthenticated: false,
  unAuthComponent: LoginRedirect,
  loadingComponent: PageLoader,
};

export default AuthRoute;
