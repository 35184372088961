import React, { useEffect, useState } from 'react';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import { useNotification, Button, Loader } from 'saagie-ui/react';
import { FieldInput, FieldSelect } from 'saagie-ui/react/formsy';
import { editProfile, getJobs, getProfile } from '../../services/userService';
import { EditProfileVerificationModal } from '../profile/EditProfileVerificationModal';
import { UNKNOWN_ERROR } from '../../constants/error-validation';
import { EMAIL_ALREADY_SENT } from '../../constants/profile-error-messages';

export const ProfileFormLogin = ({ login, onLogin, isReadOnly }) => {
  const [availableJobs, setAvailableJobs] = useState({});

  // State to check if an email exists in database so we prevent the user
  // not to submit an empty email.
  const [doesEmailExist, setDoesEmailExist] = useState(false);

  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);
  const [isLoadingVerification, setIsLoadingVerification] = useState(false);
  const [isLoadingToChangeProfile, setIsLoadingToChangeProfile] = useState(false);

  const [isInCodeVerification, setIsInCodeVerification] = useState(false);

  const [emailUpdates, setEmailUpdates] = useState(undefined);
  const [jobUpdates, setJobUpdates] = useState(undefined);

  const [job, setJob] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [isValidForm, setIsValidForm] = useState(true);

  const [isResendCodeBlocked, setIsResendCodeBlocked] = useState(false);

  const { throwError, throwImportant } = useNotification();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getJobs();

        setAvailableJobs(response.data
          .reduce((acc, { id, title }) => {
            acc[id] = title;
            return acc;
          }, {}));
      } catch (err) {
        throwError('Unable to fetch jobs');
      }

      setIsLoadingJobs(false);
    };

    fetchJobs();
  }, [throwError]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await getProfile(login);

        setEmailUpdates(data?.email ?? undefined);
        setJobUpdates(data?.job ?? undefined);
        setEmail(data?.email ?? undefined);
        setJob(data?.job ?? undefined);
        setDoesEmailExist(data?.email?.length > 0);
      } catch (err) {
        throwError(`Unable to fetch profile data for user ${login}`);
      }

      setIsLoadingProfile(false);
    };

    fetchProfile();
  }, [login, throwError]);

  useEffect(() => {
    let timeout;
    if (isResendCodeBlocked) {
      timeout = setTimeout(() => {
        setIsResendCodeBlocked(false);
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [isResendCodeBlocked]);

  const hasEmailChanged = (emailInput) => ((emailInput || undefined) !== emailUpdates);

  const handleSubmitWithoutConfirmation = async (values, _, invalidateForm) => {
    if (job || email) {
      setIsLoadingToChangeProfile(true);

      try {
        await editProfile({ login, job: values.job, email: values.email });

        if (hasEmailChanged(values.email)) {
          setJobUpdates(values.job);
          setIsInCodeVerification(true);
          setIsLoadingToChangeProfile(false);
        } else {
          onLogin();
        }
      } catch (error) {
        const { data } = error.response;

        if (data === EMAIL_ALREADY_SENT) {
          setIsInCodeVerification(true);
          throwImportant(EMAIL_ALREADY_SENT, null, { duration: 10 });
        } else {
          invalidateForm({
            email: data[0] === '<' ? UNKNOWN_ERROR : data,
          });
        }

        setIsLoadingToChangeProfile(false);
      }
    } else {
      onLogin();
    }
  };

  const handleSubmitWithConfirmation = async (values, _, invalidateForm) => {
    setIsLoadingVerification(true);

    try {
      await editProfile({
        login, job: jobUpdates, email, verificationCode: values.code,
      });

      onLogin();
    } catch (error) {
      const { data } = error.response;
      invalidateForm({
        code: data[0] === '<' ? UNKNOWN_ERROR : data,
      });
      setIsLoadingVerification(false);
    }
  };

  const onResendCode = async () => {
    setIsLoadingVerification(true);

    if (isResendCodeBlocked) {
      setIsLoadingVerification(false);
      throwImportant('Please wait a few minutes before retrying.', null, { duration: 6 });

      return;
    }

    setIsResendCodeBlocked(true);

    try {
      await editProfile({
        login, job: jobUpdates, email, resend: true,
      });
    } catch (error) {
      const { data } = error.response;
      throwError(data);
    }

    setIsLoadingVerification(false);
  };

  const options = Object.keys(availableJobs)
    .map((key) => ({
      value: key,
      label: availableJobs[key],
    }))
    .sort((option1, option2) => option1.label.localeCompare(option2.label));

  if (isLoadingJobs || isLoadingProfile) {
    return (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Formsy
        onValidSubmit={handleSubmitWithoutConfirmation}
        onInvalid={() => setIsValidForm(false)}
        onValid={() => setIsValidForm(true)}
        onChange={(values) => {
          setJob(values.job ?? undefined);
          setEmail(values.email || undefined);
        }}
      >
        <FieldSelect
          label="Job title"
          name="job"
          options={options}
          value={job}
        />
        <FieldInput
          name="email"
          label="Email"
          required={doesEmailExist}
          helper={!isReadOnly && 'Email is only going to be used for "Forgot password" feature.'}
          value={email}
          validations={{
            isEmail: true,
          }}
          validationErrors={{
            isEmail: 'Please use a valid email address',
            isDefaultRequiredValue: 'Please keep your email address or change it',
          }}
        />
        <div className="sui-m-form-group">
          <div className="sui-g-grid as--auto as--middle as--end">
            <div className="sui-g-grid__item">
              <Button
                color="primary"
                isLoading={isLoadingJobs || isLoadingProfile || isLoadingToChangeProfile}
                isDisabled={!isValidForm}
                type="submit"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Formsy>

      <EditProfileVerificationModal
        onClose={() => setIsInCodeVerification(false)}
        onSubmit={handleSubmitWithConfirmation}
        onResendCode={onResendCode}
        isLoadingResendCode={isLoadingVerification}
        isOpen={isInCodeVerification}
      />
    </>
  );
};

ProfileFormLogin.propTypes = {
  login: PropTypes.string.isRequired,
  onLogin: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};
