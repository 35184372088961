/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PageLayout from '../components/layout/PageLayout';
import { hideLayout, showLayout } from '../utils/layout';
import { getPlatformId } from '../utils/router';
import { APP_NAME } from '../constants/app-name';

class UnauthorizedPage extends React.Component {
  componentDidMount() {
    const platformId = getPlatformId();
    showLayout(APP_NAME, platformId);
  }

  componentWillUnmount() {
    hideLayout();
  }

  render() {
    return (
      <PageLayout>
        <div className="sui-l-container as--gutter-horizontal-xl@sm as--size-sm">
          <div className="sui-l-error">
            <div className="sui-l-error__content">
              <h1 className="sui-l-error__title as--size-xxl as--size-default@sm">
                You're not allowed here
              </h1>
              <h2 className="sui-l-error__type as--size-md as--size-lg@sm">
                The privileges you’ve been assigned do not allow you to access
                any applications in this platform.
              </h2>
              <h2 className="sui-l-error__type as--size-md as--size-lg@sm">
                Please contact your admin.
              </h2>
            </div>
            <div className="sui-l-error__illustration">
              <img width="700px" src="/404.svg" alt="" />
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }
}

export default UnauthorizedPage;
