import axios from 'axios';

// TOOLS
export const configWithRealm = () => {
  const parts = window.location.hostname.split('-');

  return (parts.length > 0)
    ? {
      headers: {
        'Saagie-Realm': parts[0],
      },
    }
    : {};
};

// REQUESTS
export const getCurrentUser = () => axios.get('/auth/api/users/security/auth', configWithRealm());

export const login = (
  username, password,
) => axios.post('/authentication/api/open/authenticate', {
  login: username.toLowerCase(),
  password,
}, configWithRealm());

export const changePassword = (
  username, currentPassword, newPassword,
  // toLowerCase: Fix in order to prevent error: https://creativedata.atlassian.net/browse/PRODUCT-2497
) => axios.put('/authentication/api/open/password/change', {
  login: username.toLowerCase(),
  oldPassword: currentPassword,
  newPassword,
}, configWithRealm());

export const getJobs = () => axios.get('/profiles/api/jobs/all', configWithRealm());

export const getProfile = (username) => axios.get(`/profiles/api/${username.toLowerCase()}`, configWithRealm());

export const editProfile = (user) => axios.put(
  `/profiles/api/${user.login.toLowerCase()}`,
  { ...user, login: user?.login?.toLowerCase() },
  configWithRealm(),
);

export const forgotPassword = (username) => axios
  // toLowerCase: Fix in order to prevent error: https://creativedata.atlassian.net/browse/PRODUCT-2497
  .put('/authentication/api/open/password/reset/trigger', { login: username.toLowerCase() }, configWithRealm());

export const resetPassword = (password, token) => axios
  .put('/authentication/api/open/password/reset', {
    password,
    token,
  }, configWithRealm());
