import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useNotification, Container, Avatar, Button,
} from 'saagie-ui/react';
import { FieldInput, FieldSelect } from 'saagie-ui/react/formsy';
import Formsy from 'formsy-react';
import { activeFocusMode, desactiveFocusMode } from '../utils/focus';
import { editProfile, getJobs, getProfile } from '../services/userService';
import { EditProfileVerificationModal } from '../components/profile/EditProfileVerificationModal';
import { EMAIL_ALREADY_SENT, PLEASE_WAIT } from '../constants/profile-error-messages';
import { UNKNOWN_ERROR } from '../constants/error-validation';
import PageLayout from '../components/layout/PageLayout';
import PageLoader from '../components/layout/PageLoader';

const BackButton = () => (
  <Button type="button" className="slg-a-button--min-size" onClick={() => window.history.back()}>
    Back
  </Button>
);

export const EditProfile = ({ isReadOnly, login }) => {
  const [availableJobs, setAvailableJobs] = useState({});

  // State to check if an email exists in database so we prevent the user
  // not to submit an empty email.
  const [doesEmailExist, setDoesEmailExist] = useState(false);

  const [emailUpdates, setEmailUpdates] = useState(undefined);
  const [jobUpdates, setJobUpdates] = useState(undefined);

  // Loading States
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);
  const [isLoadingVerification, setIsLoadingVerification] = useState(false);
  const [isLoadingToChangeProfile, setIsLoadingToChangeProfile] = useState(false);

  const [isInCodeVerification, setIsInCodeVerification] = useState(false);

  const [job, setJob] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [isValidForm, setIsValidForm] = useState(false);

  const { throwError, throwImportant, throwSuccess } = useNotification();

  useEffect(() => {
    activeFocusMode();

    return () => desactiveFocusMode();
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getJobs();

        setAvailableJobs(response.data
          .reduce((acc, { id, title }) => {
            acc[id] = title;
            return acc;
          }, {}));
      } catch (err) {
        throwError('Unable to fetch jobs');
      }

      setIsLoadingJobs(false);
    };

    fetchJobs();
  }, [throwError]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await getProfile(login);

        setEmailUpdates(data?.email ?? undefined);
        setJobUpdates(data?.job ?? undefined);
        setEmail(data?.email ?? undefined);
        setJob(data?.job ?? undefined);
        setDoesEmailExist(data?.email?.length > 0);
      } catch (err) {
        throwError(`Unable to fetch profile data for user ${login}`);
      }

      setIsLoadingProfile(false);
    };

    fetchProfile();
  }, [login, throwError]);

  const onResendCode = async () => {
    setIsLoadingVerification(true);

    try {
      await editProfile({
        login, job: jobUpdates, email, resend: true,
      });
    } catch (error) {
      const { data } = error.response;

      if (data === PLEASE_WAIT) {
        throwImportant(PLEASE_WAIT);
      } else {
        throwError(data);
      }
    }

    setIsLoadingVerification(false);
  };

  const showSuccessNotification = () => {
    throwSuccess(
      <>
        <span role="img" aria-label="tada" className="sui-h-mr-xs">
          🎉
        </span>
        Profile Saved
      </>,
      null,
      {
        duration: 6,
      },
    );
  };

  const hasEmailChanged = (emailInput) => ((emailInput || undefined) !== emailUpdates);
  const isInformationModified = () => job !== jobUpdates || email !== emailUpdates;

  const handleSubmitWithoutConfirmation = async (values, _, invalidateForm) => {
    setIsLoadingToChangeProfile(true);

    try {
      await editProfile({ login, job: values.job, email: values.email });

      if (hasEmailChanged(values.email)) {
        setIsInCodeVerification(true);
      } else {
        showSuccessNotification();
      }

      setJobUpdates(values.job ?? undefined);
    } catch (error) {
      const { data } = error.response;

      // If the email is already sent, we just want to show the modal to enter the code. Not warn
      // user, it is not really an error. This has to be changed in backend, so we can move this
      // logic, and remove the condition.
      if (data === EMAIL_ALREADY_SENT) {
        setIsInCodeVerification(true);
        throwImportant(EMAIL_ALREADY_SENT, null, { duration: 10 });
      } else {
        invalidateForm({
          email: data[0] === '<' ? UNKNOWN_ERROR : data,
        });
      }
    }

    setIsLoadingToChangeProfile(false);
  };

  const handleSubmitWithConfirmation = async (values, resetForm, invalidateForm) => {
    setIsLoadingVerification(true);

    try {
      await editProfile({
        login, job: jobUpdates, email, verificationCode: values.code,
      });

      setDoesEmailExist(true);
      resetForm();
      setIsInCodeVerification(false);
      setEmailUpdates(email);

      showSuccessNotification();
    } catch (error) {
      const { data } = error.response;
      invalidateForm({
        code: data[0] === '<' ? UNKNOWN_ERROR : data,
      });
    }

    setIsLoadingVerification(false);
  };

  const options = useMemo(
    () => Object.keys(availableJobs)
      .map((key) => ({
        value: key,
        label: availableJobs[key],
      }))
      .sort((option1, option2) => option1.label.localeCompare(option2.label)), [availableJobs],
  );

  if (isLoadingJobs || isLoadingProfile) {
    return (<PageLoader />);
  }

  return (
    <>
      <Formsy
        onValidSubmit={handleSubmitWithoutConfirmation}
        onInvalid={() => setIsValidForm(false)}
        onValid={() => setIsValidForm(true)}
        onChange={(values) => {
          setJob(values.job ?? undefined);
          setEmail(values.email || undefined);
        }}
      >
        <div className="sui-l-app-layout__main">
          <PageLayout>
            <Container size="md" gutter="xl@xxs">
              <div className="sui-l-page-header">
                <div className="sui-l-page-header__primary">
                  <div className="sui-l-page-header__title">
                    <div className="sui-m-media-object as--middle">
                      <div className="sui-m-media-object__media">
                        <Avatar />
                      </div>
                      <div className="sui-m-media-object__content">
                        <div className="sui-a-label-value as--lg">
                          <div className="sui-a-label-value__value">
                            {login}
                          </div>
                          <div className="sui-a-label-value__label">
                            {(job ? availableJobs[job] : '') || (jobUpdates ? availableJobs[jobUpdates] : '')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sui-l-page-header__secondary">
                  <BackButton />
                </div>
              </div>

              <Container gutterHorizontal="none">
                <div className="sui-m-tabs">
                  <div className="sui-m-tabs__item as--active">
                    Your information
                  </div>
                </div>
              </Container>

              {/* Form body. */}
              <Container gutterHorizontal="none">
                <FieldInput
                  name="login"
                  label="Username"
                  helper="You can not modify your username for security reasons."
                  value={login}
                  disabled
                  required
                  inputProps={{ 'data-testid': 'username-profile' }}
                />
                <FieldSelect
                  label="Job title"
                  name="job"
                  value={job}
                  options={options}
                  selectProps={{ 'data-testid': 'jobtitle-profile' }}
                />
                <FieldInput
                  name="email"
                  label="Email"
                  value={email}
                  helper={!isReadOnly ? 'Email will be used for "Forgot password" feature and internal alert system.' : ''}
                  required={doesEmailExist}
                  validations={{
                    isEmail: true,
                  }}
                  validationErrors={{
                    isEmail: 'Please use a valid email address',
                    isDefaultRequiredValue: 'Please keep your email address or change it.',
                  }}
                  inputProps={{ 'data-testid': 'email-profile' }}
                />
              </Container>
            </Container>
          </PageLayout>

          <div className="sui-l-app-layout__page-footer">
            <Container gutter="xl@xxs" size="md">
              <div className="sui-g-grid as--auto as--middle as--center">
                <div className="sui-g-grid__item as--full as--fill@sm sui-h-text-center" />
                <div className="sui-g-grid__item as--first@sm">
                  <BackButton />
                </div>
                <div className="sui-g-grid__item as--end@sm">
                  <Button
                    color="primary"
                    type="submit"
                    isLoading={isLoadingJobs || isLoadingProfile || isLoadingToChangeProfile}
                    isDisabled={!isValidForm || !isInformationModified()}
                    className="slg-a-button--min-size"
                  >
                    Save profile
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Formsy>

      <EditProfileVerificationModal
        onClose={() => setIsInCodeVerification(false)}
        onSubmit={handleSubmitWithConfirmation}
        onResendCode={onResendCode}
        isLoadingResendCode={isLoadingVerification}
        isOpen={isInCodeVerification}
      />
    </>
  );
};

EditProfile.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  login: PropTypes.string.isRequired,
};
