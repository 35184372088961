import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalCloseButton, ModalHeader, ModalTitle, ModalBody, Container, ModalFooter,
} from 'saagie-ui/react';
import { FieldInput } from 'saagie-ui/react/formsy';
import Formsy from 'formsy-react';

const VERIFICATION_CODE_LENGTH = 6;

export const EditProfileVerificationModal = ({
  onClose, onSubmit, onResendCode, isLoadingResendCode, isOpen,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  const resetForm = () => {
    setResetKey((x) => x + 1);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isClickOutsideDisabled isEscapeKeyDisabled>
      <ModalHeader>
        <ModalCloseButton />
        <ModalTitle>
          Confirm your new email
        </ModalTitle>
      </ModalHeader>
      <Formsy
        onValidSubmit={(values, _, invalidateForm) => {
          onSubmit(values, resetForm, invalidateForm);
        }}
        onInvalid={() => setIsFormValid(false)}
        onValid={() => setIsFormValid(true)}
        key={resetKey}
      >
        <ModalBody>
          <Container gutterHorizontal="none">
            <div>
              An email with a validation code has been sent.
            </div>
            <div>
              Enter the number code to continue.
            </div>
          </Container>

          <Container gutterHorizontal="none" gutterVertical="sm">
            <FieldInput
              name="code"
              label="Validation code"
              type="text"
              required
              validations={{
                isCorrectLength: (_, value) => (value || '').length === VERIFICATION_CODE_LENGTH,
                isNumeric: true,
              }}
              validationErrors={{
                isCorrectLength: 'Code should be 6 digits long',
                isNumeric: 'Code should contains only digits',
              }}
            />
            <div className="sui-h-mv-md">
              <Button color="link" className="sui-h-pl-none" onClick={onResendCode}>
                Resend validation code
              </Button>
            </div>
          </Container>
        </ModalBody>

        <ModalFooter>
          <div className="sui-g-grid">
            <div className="sui-g-grid__item as--push">
              <Button color="primary" isLoading={isLoadingResendCode} isDisabled={!isFormValid} type="submit">
                Confirm email
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Formsy>
    </Modal>
  );
};

EditProfileVerificationModal.propTypes = {
  isLoadingResendCode: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onResendCode: PropTypes.func,
  onSubmit: PropTypes.func,
};

EditProfileVerificationModal.defaultProps = {
  isLoadingResendCode: false,
  isOpen: false,
  onClose: () => {},
  onResendCode: () => {},
  onSubmit: () => {},
};
