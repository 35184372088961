import React from 'react';
import PropTypes from 'prop-types';

const OfflineLayout = ({ children }) => (
  <div className="sui-l-offline-splitted">
    <div className="sui-l-offline-splitted__aside">
      <div className="sui-l-offline-splitted__logo">
        <img alt="Saagie" src="/logo.svg" />
      </div>
      <div className="sui-l-offline-splitted__mascot">
        <img alt="" src="/mascot.svg" />
      </div>
    </div>
    <div className="sui-l-offline-splitted__content">
      <div className="sui-l-offline-splitted__content-inner">
        <div className="sui-l-container as--size-xxs">
          { children }
        </div>
      </div>
    </div>
  </div>
);

OfflineLayout.propTypes = {
  children: PropTypes.node,
};

OfflineLayout.defaultProps = {
  children: '',
};

export default OfflineLayout;
