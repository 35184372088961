import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { LoadLayoutData } from '@saagie-internal/layout-lib';
import { NotificationProvider } from 'saagie-ui/react';
import ChangePassword from './pages/ChangePassword';
import OfflineSkeleton from './components/layout/OfflineSkeleton';
import Login from './pages/Login';
import SingleSignOn from './pages/SingleSignOn';
import Logout from './pages/Logout';
import { HttpErrorPage } from './pages/HttpErrorPage';
import LoginRedirect from './components/router/LoginRedirect';
import { HomeRedirect } from './components/router/HomeRedirect';
import AuthRoute from './components/router/AuthRoute';
import { getCurrentUser } from './services/userService';
import { EditProfile } from './pages/EditProfile';
import { ForgotPassword } from './pages/ForgotPassword';
import {
  FORGOT_PASSWORD_URL, UNAUTHORIZED_PATH, PLATFORMS_URL, RESET_PASSWORD_URL,
} from './constants/url';
import { ResetPassword } from './pages/ResetPassword';
import PlatformList from './pages/PlatformList';
import UnauthorizedPage from './pages/UnauthorizedPage';

export const App = () => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUsername, setCurrentUsername] = useState(null);

  useEffect(() => {
    const loadLayoutData = new LoadLayoutData();

    const layoutSetup = async () => {
      setIsReadOnly(await loadLayoutData.getIsReadOnlyPromise());
    };

    layoutSetup();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCurrentUser();
        const { login } = response.data;

        setIsAuthenticated(true);
        setCurrentUsername(login);
      } catch (_) {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const onLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <NotificationProvider>
      <Router>
        <div className="sui-l-app-layout__main">
          <Switch>
            <AuthRoute
              exact
              path="/"
              authComponent={HomeRedirect}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
            <AuthRoute
              exact
              path="/login"
              authComponent={HomeRedirect}
              unAuthComponent={() => (
                <Login onLogin={onLogin} isReadOnly={isReadOnly} />
              )}
              loadingComponent={OfflineSkeleton}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
            {
              window.ENV?.authProvider === 'sso'
                && (
                  <AuthRoute
                    exact
                    path="/single-sign-on"
                    authComponent={HomeRedirect}
                    unAuthComponent={() => (
                      <SingleSignOn />
                    )}
                    loadingComponent={OfflineSkeleton}
                    isLoading={isLoading}
                    isAuthenticated={isAuthenticated}
                  />
                )
            }
            {
              window.ENV?.authProvider === 'sso'
                && (
                  <AuthRoute
                    exact
                    path="/logout"
                    authComponent={HomeRedirect}
                    unAuthComponent={() => (
                      <Logout />
                    )}
                    loadingComponent={OfflineSkeleton}
                    isLoading={isLoading}
                    isAuthenticated={isAuthenticated}
                  />
                )
            }
            <AuthRoute
              exact
              path={FORGOT_PASSWORD_URL}
              authComponent={HomeRedirect}
              unAuthComponent={({ match }) => (<ForgotPassword match={match} />)}
              loadingComponent={OfflineSkeleton}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
            <AuthRoute
              exact
              path={`${RESET_PASSWORD_URL}/:token`}
              authComponent={HomeRedirect}
              unAuthComponent={({ match }) => (<ResetPassword match={match} />)}
              loadingComponent={OfflineSkeleton}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
            <AuthRoute
              exact
              path={PLATFORMS_URL}
              authComponent={() => (
                <PlatformList />
              )}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
            <AuthRoute
              exact
              path={`${UNAUTHORIZED_PATH}/platform/:platformId`}
              authComponent={UnauthorizedPage}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
            <AuthRoute
              exact
              path="/change-password"
              authComponent={ChangePassword}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
            <AuthRoute
              exact
              path="/403"
              authComponent={() => (<HttpErrorPage message="Forbidden" code="403" />)}
              unAuthComponent={LoginRedirect}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
            <AuthRoute
              exact
              path="/edit-profile"
              authComponent={() => (
                <EditProfile login={currentUsername} isReadOnly={isReadOnly} />
              )}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
            <AuthRoute
              authComponent={HttpErrorPage}
              unAuthComponent={LoginRedirect}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
          </Switch>
        </div>
      </Router>
    </NotificationProvider>
  );
};
