import React from 'react';
import OfflineLayout from './OfflineLayout';

const OfflineSkeleton = () => (
  <OfflineLayout>
    <div className="sui-h-text-center">
      <span className="sui-a-loader as--xl" />
    </div>
  </OfflineLayout>
);

export default OfflineSkeleton;
